import {
  priceMarkerDecimals,
  cpc_calculatorId,
  baseCalculatorNewApiUrl,
  cpc_productId,
} from "./global_variables";

export function buildDialogBlock() {
  return "<div style='display:none;'><div id='dialog' title='Basic dialog'><p>Your product isn't ready to go live. <br/>Delete all variants from the product and click save. <br/> </br/> Click <a target='_blank' href='https://apippa.freshdesk.com/support/solutions/articles/67000321459-add-to-cart-not-working'>here</a> to learn more.</p></div></div>";
}

export function buildErrorBlock() {
  return "<div id='alert-block' class='hide-element alert float-left mt-4 w-100 clearfix alert-danger'>Please upload an image</div>";
}

export function generateStep(size) {
  var num = 1;
  var s = num + "";
  while (s.length < size) s = "0" + s;
  if (size > 0) {
    s = "0." + s;
  }
  return parseFloat(s);
}

export function showDialog() {
  jQuery("#dialog").dialog({
    draggable: false,
    resizable: false,
    title: "Custom Price Calculator Alert!",
    width: 430,
    height: 230,
  });
}

export function globalHelper_Escape(input) {
  input = input.replace(/\"/g, '\\"');
  return input;
}

export function globalHelper_ReportError(err, msg1 = "") {
  var sourceUrl = window.location.href;
  var errMsg = JSON.stringify(err, Object.getOwnPropertyNames(err));
  errMsg = errMsg + ", Msg1 = " + msg1 + ", SourceUrl = " + sourceUrl;

  jQuery
    .ajax({
      method: "POST",
      url: baseCalculatorNewApiUrl + "logs/error",
      data: JSON.stringify({
        calculatorId: cpc_calculatorId,
        productId: cpc_productId,
        errorMessage: errMsg,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .done(function () {});
}

export function formatPricing(
  number,
  thousandSep,
  decimalSep,
  overrideDecimalsTo2 = false
) {
  let thousandSeparator;
  if (thousandSep === 1) {
    thousandSeparator = ",";
  } else if (thousandSep === 2) {
    thousandSeparator = ".";
  } else if (thousandSep === 3) {
    thousandSeparator = " ";
  } else {
    thousandSeparator = "";
  }

  // Define the decimal separator
  const decimalSeparatorStr = decimalSep === 1 ? "," : ".";

  // Split the number into integer and decimal parts
  const parts = number
    .toFixed(overrideDecimalsTo2 ? 2 : priceMarkerDecimals)
    .split(".");

  // Format the integer part with thousand separator
  let formattedInteger = parts[0].replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandSeparator
  );

  // Combine the integer and decimal parts with the decimal separator
  const formattedNumber =
    formattedInteger + (parts.length > 1 ? decimalSeparatorStr + parts[1] : "");

  return formattedNumber;
}

export function PreventCheckoutOnEnter() {
  $("#calculator").on("keypress", function (event) {
    console.log("checking for enter whether preseed for form.");
    var keyPressed = event.keyCode || event.which;
    if (keyPressed === 13) {
      console.log("Found Enter pressed for form.");
      event.preventDefault();
      return false;
    }
  });
}
