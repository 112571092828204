import {
  cpc_waitforcartconfirmation,
  calculatorStyling,
  cpc_product_default_price,
  cpc_metaFields,
  productForm,
  isError,
} from "./global_variables";
import { CheckNumberInputValidity } from "./build_elements";

export function SubmitFormData() {
  jQuery(".loading-anim").show();
  console.log("posting product data...");
  var formm = productForm;

  let quantity = 1;
  //console.log('before delete');
  console.log(formm);
  [...formm.elements].forEach((e) => {
    if (jQuery(e).data("cpc_isquantity") === true) {
      let name = jQuery(e).attr("name");
      quantity = formm.elements[name].value;
      jQuery(e).remove();
    }
  });

  //console.log('after delete');
  //console.log(formm);

  // var dataString = jQuery(formm).serialize();
  var formData = new FormData(formm);
  formData.append("quantity", quantity);
  jQuery.ajax({
    type: "POST",
    url: "/cart/add",
    data: formData,
    xhr: function () {
      var xhr = new window.XMLHttpRequest();
      if (
        calculatorStyling !== null &&
        calculatorStyling.fileUploadProgressText !== null &&
        calculatorStyling.fileUploadProgressText !== ""
      ) {
        let cpc_file_upload_count = 1;
        xhr.upload.addEventListener(
          "progress",
          function (evt) {
            cpc_file_upload_count++;
            if (evt.lengthComputable && cpc_file_upload_count > 2) {
              var percentComplete = (evt.loaded / evt.total) * 100;
              console.log("percentComplete", percentComplete);
              percentComplete = percentComplete.toFixed(2);
              jQuery("#cpc_loader_text").html(
                calculatorStyling.fileUploadProgressText +
                  " " +
                  percentComplete +
                  " %"
              );
            }
          },
          false
        );
      }
      return xhr;
    },
    //timeout: 1200000,
    processData: false,
    contentType: false,
    success: function () {
      if (calculatorStyling !== null && calculatorStyling.loaderText !== "")
        jQuery("#cpc_loader_text").html(calculatorStyling.loaderText);
      IsShopifyCartReady();
    },
    error: function (err) {
      console.log("add to cart request errored", err);

      if (calculatorStyling !== null && calculatorStyling.loaderText !== "")
        jQuery("#cpc_loader_text").html(calculatorStyling.loaderText);
      IsShopifyCartReady();
    },
  });
}

function IsShopifyCartReady(count = 0) {
  var ajaxCartClasses = []; //['cart-link__bubble--visible', 'cart-link'];
  var ajaxFound = false;
  var ajaxCartClass = null;
  ajaxCartClasses.forEach((cartElem) => {
    if (ajaxFound === false && jQuery("." + cartElem)[0]) {
      ajaxCartClass = cartElem;
      ajaxFound = true;
    }
  });

  if (cpc_waitforcartconfirmation === false) {
    if (ajaxFound === true) {
      jQuery("." + ajaxCartClass).trigger("click");
    } else {
      window.location.href = "/cart";
    }
    jQuery(".loading-anim").hide();
  } else {
    fetch("/cart.js?cache=false&q=" + Math.random())
      .then((response) => response.json())
      .then(function (response) {
        var cartDataAsString = JSON.stringify(response);
        var zeroPriceIndex = cartDataAsString.search('"price":0');
        if (zeroPriceIndex === -1) {
          if (ajaxFound === true) {
            jQuery("." + ajaxCartClass).trigger("click");
          } else {
            window.location.href = "/cart";
          }
          jQuery(".loading-anim").hide();
        } else {
          count++;
          setTimeout(() => {
            console.log("waiting for cart confirmation");
            IsShopifyCartReady(count);
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  }
}

export function GetVariantProperties() {
  var props = [];
  var lookupProperties = {};
  jQuery(".calc-prop").each(function (index, element) {
    let isHidden = jQuery(element).is(":hidden");
    let isLookupElement = jQuery(element).data("lookup") !== undefined;
    let IsLookupFormulaElement = isLookupElement
      ? jQuery(element).data("isformula") === true
      : false;

    var elementType = jQuery(element).prop("type");
    if (elementType == "number") {
      var result = CheckNumberInputValidity(element);
      if (result !== undefined && !result) {
        isError = true;
        jQuery(element).addClass("error-border");
        return;
      }
    }

    if (isHidden === false || IsLookupFormulaElement === true) {
      var name = jQuery(element).attr("name");
      var value = jQuery(element).val();

      let isCheckBox = jQuery(element).is(":checkbox");
      let isDropdown = jQuery(element).is("select");
      var elementType = jQuery(element).prop("nodeName");
      if (jQuery(element).is(":radio") === true) {
        let isChecked = jQuery(element).is(":checked");
        if (isChecked === false) return true;
        //Check if this radio button belongs to swatch. Only set the value if its regular radio button
        if (jQuery(element).data("showimage") === undefined) {
          value = jQuery(element).data("val");
        }
      } else if (elementType === "LABEL") {
        value = jQuery(element).data("val");
      } else if (isCheckBox === true) {
        let isChecked = jQuery(element).is(":checked");
        let checkedValue = jQuery(element).data("checkedvalue");
        let uncheckedValue = jQuery(element).data("uncheckedvalue");
        value = isChecked ? "Checked" : "Unchecked";
      } else if (isDropdown === true) {
        value = jQuery(element).children("option:selected").data("label");
      }

      name = name.replace("properties[", "");
      name = name.replace("]", "");
      if (name === "length") {
        name = "Length";
      }

      //For lookup element prepare the data
      if (isLookupElement === true) {
        let lookupelementindex = jQuery(element).data("lookupelementindex");
        let elementIdentifier = jQuery(element).data("identifier");
        if (typeof lookupProperties[elementIdentifier] === "undefined") {
          var lookupElementProcessedElement = {
            elementName: elementIdentifier,
            input1: 0,
            input2: 0,
          };

          if (lookupelementindex === 1)
            lookupElementProcessedElement.input1 = parseFloat(
              jQuery(element).val()
            );
          else if (lookupelementindex === 2)
            lookupElementProcessedElement.input2 = parseFloat(
              jQuery(element).val()
            );

          lookupProperties[elementIdentifier] = lookupElementProcessedElement;
        } else {
          let lookupElementProcessedElement =
            lookupProperties[elementIdentifier];
          if (lookupelementindex === 1)
            lookupElementProcessedElement.input1 = parseFloat(
              jQuery(element).val()
            );
          else if (lookupelementindex === 2)
            lookupElementProcessedElement.input2 = parseFloat(
              jQuery(element).val()
            );
        }
      } else {
        props[name] = value;
      }
    }
  });

  //Now process lookup element
  for (var key in lookupProperties) {
    props[key] = lookupProperties[key];
  }

  props["shopify_product_price"] = cpc_product_default_price;

  //apply meta fields
  cpc_metaFields.forEach((item) => {
    props["shopify_meta_" + item.key] = item.value;
  });

  return props;
}
