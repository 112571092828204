import {
  cpc_calculatorId,
  cpc_waitforcartconfirmation,
  baseCalculatorNewApiUrl,
  calculatorStyling,
  cpc_data,
  cpc_configData,
  cpc_shopUrl,
  cpc_isPreview,
  cpc_metaFields,
  cdnURL,
} from "./global_variables";
import { buildCalculator } from "./build_elements";
import { loadScripts } from "./everything_else";
import { UpdatePrice, ProcessConditionalElements } from "./price_calculation";
import { PreventCheckoutOnEnter } from "./common_utils";

async function LoadCalculator(calculatorId, isPreview) {
  var url =
    baseCalculatorNewApiUrl +
    "calculator/app?calculatorId=" +
    calculatorId +
    "&isPreview=" +
    isPreview +
    "&shop=" +
    cpc_shopUrl;
  return await fetch(url);
}

export async function ProcessNonProductPage({
  calcUniqueIden,
  elementSelector,
}) {
  var calculatorId;

  calculatorId = calcUniqueIden;
  //Fall back to old way if we cant find the calculatorId from the server
  if (calculatorId == null || calculatorId == 0 || calculatorId == undefined) {
    var calculatorElement = document.getElementById("calculator-block");
    var customCalculatorDiv = document.getElementById("custom-calculator");
    calculatorId = jQuery(customCalculatorDiv).attr("data-calculatorid");

    if (calculatorId != null && calculatorId != undefined)
      console.log("CalculatorId found from Fallback Method", calculatorId);
    else {
      console.log("No calculator found");
      return;
    }
  }

  if (calculatorId != null && calculatorId != undefined) {
    cpc_calculatorId = calculatorId;
    console.log("calculator found", calculatorId);

    loadScripts();

    var priceElements = [
      "product-price",
      "product-single__price",
      "selector-wrapper",
      "current_price",
      "product__price",
      "shopify-payment-button",
      "single-option-selector",
    ];
    priceElements.forEach((elem) => {
      var elementToHide = jQuery("." + elem);
      elementToHide.addClass("hide-element");
    });

    //Load css
    var fileref = document.createElement("link");
    fileref.setAttribute("rel", "stylesheet");
    fileref.setAttribute("type", "text/css");
    fileref.setAttribute(
      "href",
      baseCalculatorNewApiUrl + "store/style/app?shop=" + cpc_shopUrl
    );
    document.getElementsByTagName("head")[0].appendChild(fileref);

    var pluginCss = document.createElement("link");
    pluginCss.setAttribute("rel", "stylesheet");
    pluginCss.setAttribute("type", "text/css");
    pluginCss.setAttribute("href", `${cdnURL}/plugins.css`);
    document.getElementsByTagName("head")[0].appendChild(pluginCss);

    //To hide the loader once page has loaded
    var customCalcBlock = jQuery("#custom-calculator");
    customCalcBlock.hide();

    var calculatorData = LoadCalculator(calculatorId, cpc_isPreview);
    calculatorData
      .then((response) => response.json())
      .then((json) => {
        cpc_data = json.calculatorData;
        cpc_metaFields = json.metaFields;
        let config = {
          smartVariantAutoDeletion: false,
          alertMessageNumberInput:
            "Please enter a value between range {input_range_min} and {input_range_max}",
          thousandSeperator: 0,
          decimalSeperator: 0,
        };
        if (!!json.configuration) config = json.configuration;
        localStorage.setItem("cpc_configData", JSON.stringify(config));
        cpc_configData = config;
        calculatorStyling = json.styling;
        cpc_waitforcartconfirmation = json.waitForCartConfirmation;

        console.log("meta-fields", cpc_metaFields);

        var builtData = buildCalculator(cpc_data);

        var elem =
          document.querySelector(elementSelector) ??
          document.querySelector(`#${elementSelector}`) ??
          document.querySelector(`.${elementSelector}`);
        if (elem == null) {
          elem = document.querySelector(
            "form[action='/cart/add'] button[type=submit]"
          );
        }

        console.log("render-block", elem);

        var borderClass =
          calculatorStyling.border === "rounded" ? "class='rounded-edges'" : "";
        jQuery(elem).append(
          "<div id='calculator' data-calcid='" +
            calculatorId +
            "' " +
            borderClass +
            " style='background-color:" +
            calculatorStyling.upperBackground +
            "'>" +
            builtData +
            "</div>"
        );

        //Custom Store JS
        var shopCustomJsPath =
          baseCalculatorNewApiUrl + "store/script/app?shop=" + cpc_shopUrl;
        if (shopCustomJsPath != null && shopCustomJsPath.length > 0) {
          var customStoreJSRef = document.createElement("script");
          customStoreJSRef.setAttribute("type", "text/javascript");
          customStoreJSRef.setAttribute("src", shopCustomJsPath);
          customStoreJSRef.defer = true;
          customStoreJSRef.setAttribute("crossorigin", "anonymous");
          document
            .getElementsByTagName("head")[0]
            .appendChild(customStoreJSRef);
        }

        ProcessConditionalElements();
        UpdatePrice();
        PreventCheckoutOnEnter();
      });
  }
}
